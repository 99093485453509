// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
}
// REMIX HMR END

import "@commural/ui/styles/reset.css?__remix_sideEffect__";
import "@commural/ui/styles/global.css?__remix_sideEffect__";
import "~/styles/global.css?__remix_sideEffect__";
import "~/styles/masonry.css?__remix_sideEffect__";
import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
export const meta = () => {
  return [{
    title: 'Commural'
  }];
};
export default function Index() {
  _s();
  const {
    t
  } = useTranslation('consumer');
  return <div>
      <h1>Welcome to Commural</h1>
      <ul>
        <li>
          <Link to="auth/login">{t('login')}</Link>
        </li>
        <li>
          <Link to="auth/signup">{t('sign up')}</Link>
        </li>
        {/* <li>
           <Link to="profile">マイページ</Link>
          </li>
          <li>
           <Link to="coupon">クーポン</Link>
          </li>
          <li>
           <Link to="notification">{t("notification")}</Link>
          </li>
          <li>
           <Link to="settings">設定</Link>
          </li> */}
        <li>
          <Link to="12345">イナリグリーン</Link>
        </li>
      </ul>
    </div>;
}
_s(Index, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;